// Import necessary modules and components

import 'tailwindcss/tailwind.css';
import './App.css';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { inference, modelDownloadInProgress } from './inference.js';

function normaliseMaxSim(maxSim) {
  const maxPossibleScore = 32; // Maximum score, given the query length
  return maxSim / maxPossibleScore;
}

class TextInputArea extends React.Component {
  constructor(props) {
    super(props);
    const queryParameters = new URLSearchParams(window.location.search);
    const query = queryParameters.get("query");
    const passage = queryParameters.get("passage");
    this.state = {
      queryText: query || 'Effects of climate change on marine ecosystems',
      documentText: passage || 'The changing climate has profound impacts on marine ecosystems. Rising temperatures, ocean acidification, and altered precipitation patterns all contribute to shifts in the distribution and behavior of marine species, influencing the delicate balance of underwater ecosystems.',
      data: null,
      maxSim: 0.0,
      maxSimNormalised: 0.0,
      tokens: null,
      downloading: modelDownloadInProgress(),
    };
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.handleDocumentChange = this.handleDocumentChange.bind(this);
    this.handleInferenceClick = this.handleInferenceClick.bind(this);
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.checkModelStatus(), 1000);
    this.handleInferenceClick();
  }

  checkModelStatus() {
    this.setState({
      downloading: modelDownloadInProgress(),
    });
    if (!this.state.downloading) {
      this.timerID = setInterval(() => this.checkModelStatus, 5000000);
    }
  }

  handleQueryChange(event) {
    this.setState({
      queryText: event.target.value,
    });
  }

  handleDocumentChange(event) {
    this.setState({
      documentText: event.target.value,
    });
  }
  

  handleInferenceClick() {
    inference(this.state.queryText, this.state.documentText).then((result) => {
      this.setState({
        data: result[1],
        maxSim: result[0],
        maxSimNormalised: normaliseMaxSim(result[0]) * 100,
        tokens: result[2],
        formattedDocument: this.formatDocumentWithHighlights3(result[2])
      });
    });
  }

  renderMaxSimScore() {
    if (this.state.maxSim > 0.0) {
      return (
        <div className="grid grid-cols-1 gap-3">
          <div className="text-base font-medium">
            MaxSim Score: <span className="font-bold">{this.state.maxSim.toFixed(2)}</span> <br />
            Estimated Relevance: <span className="font-bold">{this.state.maxSimNormalised.toFixed(2)}%</span> <br />
          </div>
          <h4 className="text-xl font-extrabold">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-red-800 via-yellow-600 to-yellow-500">Contextualised Highlights</span>
          </h4>
        </div>
      );
    } else {
      return null;
    }
  }

  highlightClass = (score, maxScore) => {
    let intensity = Math.floor((score / maxScore) * 100);
    return `bg-yellow-${intensity} font-bold p-1 rounded`;
  };
  
  formatDocumentWithHighlights3(tokens) {
    // Find the maximum maxScore in the array of tokens
    const maxScore = Math.max(...tokens.map(token => token.max));
    const formattedWords = tokens.map((token, index) => {
      const score = token.max;
      if (score > 0.0) {
        const intensity = Math.floor((score / maxScore) * 255);
        const backgroundColor = `rgba(255, ${255 - intensity}, ${255 - intensity}, 0.3)`;
        //const fontSize = 100 + 20 *  Math.floor(score / maxScore);
        const style = {
          backgroundColor,
          borderRadius: '0.5rem',
          //fontSize: `${fontSize}%`,
        };
        // Adding a title attribute for tooltip
        return (
          <span key={index} className="font-bold p-1" style={style} title={`MaxSim Score: ${score.toFixed(2)}`}>
            {token.word.trim()}
          </span>
        );
      } else {
        return (
          <span key={index} className="p-1">
            {token.word.trim()}
          </span>
        );
      }
    });
  
    return <div>{formattedWords}</div>;
  }
  
render() {
  return (
    <div className="App container mx-auto p-4 max-w-3xl"> 
      <div className="grid grid-cols-1 gap-3">
        <div>
          <a href="https://github.com/stanford-futuredata/ColBERT/" target="_blank" rel="noopener noreferrer">
            <img src="https://raw.githubusercontent.com/stanford-futuredata/ColBERT/main/docs/images/colbertofficial.png" alt="ColBERT Logo" className="mx-auto max-w-full h-auto" />
          </a>
        </div>
        <p className="text-lg italic">ColBERT query-passage scoring interpretability</p>

        {this.state.downloading && (
          <div>
            <p className="text-base">Downloading the ColBERT model from HF to your browser..</p>
            <Box sx={{ width: '200px', margin: 'auto' }}>
              <LinearProgress />
            </Box>
            <p></p>
          </div>
        )}

        <div className="grid grid-cols-1 gap-3">
          <div>
            <label htmlFor="query" className="text-sm font-bold" >Query (max 32 tokens)</label>
            <textarea
              id="query"
              rows="2"
              className="w-full p-2 rounded-lg border-2 border-gray-300"
              value={this.state.queryText}
              onChange={this.handleQueryChange}
            ></textarea>
          </div>

          <div>
            <label htmlFor="document" className="text-sm font-bold">Passage: (max 500 tokens)</label>
            <textarea
              id="document"
              rows="5"
              className="w-full p-2 rounded-lg border-2 border-gray-300 resize-y"
              value={this.state.documentText}
              onChange={this.handleDocumentChange}
            ></textarea>
          </div>

          <div className="w-3/4 mx-auto"> {/* Smaller button, centered */}
            <button
              className="bg-green-400 hover:bg-green-500 text-gray-800 font-bold py-2 px-4 rounded w-full"
              onClick={this.handleInferenceClick}
            >
              Run ColBERT scoring for query - passage
            </button>
          </div>
        </div>

        {this.renderMaxSimScore()}

        {this.state.formattedDocument && (
          <div className="p-4 border-2 border-gray-300 rounded-lg text-wrap break-words text-left leading-7">
            <div>
              {this.state.formattedDocument}
            </div>
          </div>
        )}

        
        {/* Centered Vespa and ColBERT logos */}
        <div>
            <a href="https://vespa.ai/" target="_blank" rel="noopener noreferrer">
              <img src="static/vespa-logo-rock.svg" alt="Vespa Logo" className="mx-auto max-h-28 p-3" />
            </a>
        </div>
      
        <div className="grid grid-cols-1 gap-1 mt-2">
          <div>
            <a href="https://docs.vespa.ai/en/embedding.html#colbert-embedder" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 text-lg">
              Vespa ColBERT (Represent ColBERT in Vespa)
            </a>
          </div>
          <div>
            <a href="https://github.com/bclavie/RAGatouille" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 text-lg">
            🪤 RAGatouille (Great library for training and running inference with ColBERT)
            </a>
          </div>
          <div>
            <a href="https://huggingface.co/vespa-engine/col-minilm" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 text-lg">
            Hugging face 🤗 Vespa Mini ColBERT model
            </a>
          </div>
          <div>
            <a href="https://huggingface.co/colbert-ir/colbertv2.0" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 text-lg">
            Hugging face 🤗 Full ColBERT model
            </a>
          </div>
        </div>
        {
          navigator.gpu ? (
            <div>
              <p className="text-lg italic">WebGPU is supported in your browser. ColBERT scoring is running on your GPU.</p>
            </div>
          ) : (
            <div>
              <p className="text-lg italic">WebGPU is not supported in your browser. ColBERT scoring is running on your CPU.</p>
            </div>
          )
        }
      </div>
    </div>
  );
}
}


export default TextInputArea;
